import { Injectable } from "@angular/core";
import { RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { IUser } from "../../interfaces/user/user.interface";
import { Group } from "../../models/content/Group";
import { Customer } from "../../models/customer/Customer";
import { StorageService } from "./storage.service";

@Injectable()
export class Globals {
  public visits: any;
  public summary: any;
  public properties: any;
  public user: IUser;
  public userRoles: any = [
    "cs_agent",
    "cs_supervisor",
    "finance",
    "content",
    "admin",
    "analytics",
  ];

  public globalsUpdated = new Subject<boolean>();

  // Navbar info
  private _navbarMetadata = new BehaviorSubject<any>(null);
  public navbarMetadata = this._navbarMetadata.asObservable();

  public parentGroup: Group = new Group();
  public parentGroups: Group[] = new Array<Group>();
  public customer: Customer = new Customer();
  public vendor: any;

  public purchase: any;

  public selectedPrice: any;
  public selectedItem: any;

  // Currencies
  public supportedCurrencies = new Array<string>();

  // URLS
  public entryUrl: RouterStateSnapshot;
  public deniedAccessUrl: RouterStateSnapshot;

  // Search
  public searchResult: any;
  public searchPriceResult: any;

  // Timezone
  public vendorTimeZoneOffSetInMinutes: any;

  // Configs
  public configs: any = {
    bs_datepicker: {
      containerClass: "theme-default fc-datetime-picker-wrapper",
      showWeekNumbers: false,
      adaptivePosition: true,
      withTimepicker: true,
      dateInputFormat: "dd/MM/yyyy",
    },
    bs_datepicker_with_timepicker: {
      containerClass: "theme-default fc-datetime-picker-wrapper",
      showWeekNumbers: false,
      adaptivePosition: true,
      withTimepicker: true,
      keepDatepickerOpened: true,
      rangeInputFormat: "MMMM Do YYYY, h:mm a",
      dateInputFormat: "MMMM Do YYYY, h:mm a",
    },
  };

  constructor(private _storageService: StorageService) { }

  public setGlobals(userInfo: any, currentClientId?: string) {
    this.user = {};
    this.user = userInfo;
    this.user.roles = this.user.roles ? this.user.roles : []; // Sometimes BE doesn't return the user roles. Just to be sure that everything will be working as it should

    // Set Current Client ID & Role Levels
    if (!(this.user?.roles?.length > 0)) {
      return;
    }

    let currentUserRole = null;
    const storageData = this._storageService.getUserSettings();
    if (currentClientId) {
      currentUserRole = this.user.roles.find((x: any) => x.client_id === currentClientId);
    } else if (storageData) {
      currentUserRole = this.user.roles.find((x: any) => x.client_id === storageData.client_id);
    }

    currentUserRole = currentUserRole ? currentUserRole : this.user.roles[0];

    this.setCurrentUserInfo(currentUserRole);
    this.updateNavbarMetadata("user", this.user);
  }

  // Reset values except the user information
  public resetGlobals() {
    this.parentGroup =
      this.parentGroups =
      this.customer =
      this.purchase =
      this.selectedPrice =
      this.selectedItem =
      this.visits =
      this.summary =
      this.properties =
      null;
  }

  public setCurrentUserInfo(userRole: any) {
    this._storageService.setSettings(userRole);

    this.user.current_client_id = userRole.client_id;
    this.user.all_role_levels = userRole.level;
    this.user.current_role_levels = this.updateLevelsForUI(userRole.level);
  }

  public updateLevelsForUI(level): string {
    let updatedRoles = level;
    if (updatedRoles.includes("god")) {
      updatedRoles = updatedRoles.replace(", god", "");
    }
    if (updatedRoles.includes("cs_agent")) {
      updatedRoles = updatedRoles.replace("cs_agent", "CS Agent");
    }
    if (updatedRoles.includes("cs_supervisor")) {
      updatedRoles = updatedRoles.replace(
        "cs_supervisor",
        "Sub Manager"
      );
    }
    if (updatedRoles.includes("finance")) {
      updatedRoles = updatedRoles.replace("finance", "Finance");
    }
    if (updatedRoles.includes("content")) {
      updatedRoles = updatedRoles.replace("content", "Content");
    }
    if (updatedRoles.includes("admin")) {
      updatedRoles = updatedRoles.replace("admin", "Admin");
    }
    if (updatedRoles.includes("analytics")) {
      updatedRoles = updatedRoles.replace("analytics", "Analytics");
    }

    return updatedRoles;
  }

  public setBsDatePickerConfig(vendor: any) {
    const dateFormat = !!vendor?.date_format
      ? vendor?.date_format?.toUpperCase()
      : "DD/MM/YYYY";
    this.configs.bs_datepicker = {
      containerClass: "theme-default fc-datetime-picker-wrapper",
      showWeekNumbers: false,
      adaptivePosition: true,
      dateInputFormat: dateFormat,
      rangeInputFormat: dateFormat,
    };
  }

  public setVendorTimeZoneOffSetInMinutes(vendor) {
    this.vendorTimeZoneOffSetInMinutes = vendor?.time_zone_offset_minutes;
  }

  updateNavbarMetadata(type: string, value: any) {
    this._navbarMetadata.next({ type: type, value: value });
  }
}
