export const reportDefenitionParameterType = [
  { id: "string", value: "String" },
  { id: "datetime", value: "Date Time" },
  { id: "enum", value: "Enum" },
  { id: "float", value: "Float" },
  { id: "bool", value: "Bool" },
  { id: "customer_email", value: "Customer Email" },
  { id: "agent_email", value: "Agent Email" },
  { id: "customer_address", value: "Customer Address" },
  { id: "customer_post_code", value: "Customer Post Code" },
  { id: "customer_address_1", value: "Customer Address 1" },
  { id: "customer_address_2", value: "Customer Address 2" },
  { id: "customer_address_3", value: "Customer Address 3" },
  { id: "customer_address_4", value: "Customer Address 4" },
  { id: "customer_address_5", value: "Customer Address 5" },
  { id: "decimal", value: "Decimal" },
  { id: "customer_name", value: "Customer Name" },
  { id: "customer_organisation_name", value: "Customer Organisation Name" },
  { id: "customer_country_code", value: "Customer Country Code" },
  { id: "parent_group", value: "Parent Group" },
  { id: "customer_code", value: "Customer Code" },
  { id: "deferred_revenue", value: "Deferred Revenue" },
  { id: "phone_number", value: "Phone Number" },
  { id: "date", value: "Date" },
  { id: "line_number", value: "Line Number" },
  { id: "remove_column", value: "Remove Column" },
  { id: "add_spacing", value: "Add Spacing" },
  { id: "customer_first_name", value: "Customer First Name" },
  { id: "customer_last_name", value: "Customer Last Name" },
  { id: "delivery_notes", value: "Delivery Notes" },
  { id: "product_name", value: "Product Name" },
  { id: "price_id", value : "Price Id"},
  { id: "price_sku_code", value : "Price Sku Code"},
  { id: "item_group_id", value : "Item Group Id"},
  { id: "item_group_code", value : "Item Group Code"},
  { id: "parent_item_group_id", value : "Parent Item Group Id"},
  { id: "parent_item_group_code", value : "Parent Item Group Code"},
  { id: "customer_dummy_email", value : "Customer Dummy Email"},
  { id: "sorting_column_name", value : "Sorting Column Name"},
  { id: "item_id", value: "Item Id" },
  { id: "item_code", value: "Item Code" },
  { id: "item_description", value: "Item Description" },
  { id: "revenue_recognition", value : "Revenue Recognition" },
  { id: "customer_has_subscription", value: "Customer Has Subscription" },
  { id: "customer_has_subscription_including_linked", value: "Customer Has Subscription Including Linked" },
  { id: "price_amount", value: "Price Amount" },
  { id: "price_currency", value: "Price Currency" },
  { id: "customer_created_at", value: "Customer Created At" },

  { id: "price_internal_name", value: "Price Internal Name" },
  { id: "price_duration_unit", value: "Price Duration Unit" },
  { id: "price_renewable_price", value: "Price Renewal Price" },
  { id: "price_renewable_price_id", value: "Price Renewal Price Id" },

  { id: "price_renewable_price_sku_code", value: "Price Renewal Sku Code" },
  { id: "price_renewable_price_internal_name", value: "Price Renewal Internal Name" },
  { id: "customer_property", value: "Customer Property" },

].sort((a, b) => a.value.localeCompare(b.value));
